import BooksIcon from 'assets/books.svg';
import FacebookIcon from 'assets/facebook.svg';
import React from 'react';
import * as Styled from './SocialMediaStyles';

const SocialMedia: React.FC = () => {
  return (
    <Styled.SocialMediaWrapper>
      <Styled.SocialMediaItem
        href="https://www.facebook.com/Agnieszka-Ka%C5%BAmierczyk-autor-103185318049058/"
        target="_blank"
        aria-label="facebook"
      >
        <FacebookIcon />
      </Styled.SocialMediaItem>
      <Styled.SocialMediaItem
        href="https://lubimyczytac.pl/autor/83530/agnieszka-kazmierczyk"
        target="_blank"
        aria-label="lubimy czytać"
      >
        <BooksIcon />
      </Styled.SocialMediaItem>
    </Styled.SocialMediaWrapper>
  );
};

export default SocialMedia;
