import { Colors, Font, Media } from 'config/CONSTANTS';
import { rem } from 'polished';
import styled, { createGlobalStyle } from 'styled-components';

export const MainContainer = styled.main`
  position: relative;
  @media (min-width: ${Media.DESKTOP}) {
    display: grid;
    grid-template-columns: 45vw 1fr;
    grid-template-rows: 1fr 8rem;
    overflow: hidden;
  }
`;

export const ContainerFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin-left: 30px;
  }

  @media (max-width: ${Media.MOBILE}) {
    flex-direction: column;
    min-height: 150px;

    p {
      margin-left: 0;
      margin-top: 20px;
    }
  }
`;

export const GlobalStyles = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
        font-size: 16px;
    }

    body {
        margin: 0;
        padding: 0;
        font-family: ${Font.FAMILY};
        background-color: ${Colors.LIGHT};
        color: ${Colors.DARK};

        @media (min-width: ${Media.DESKTOP}) {
            overflow: hidden;
            height: 100vh;
        }
    }

    a {
        position: relative;
        font-family: ${Font.FAMILY};
        font-size: ${rem('14px')};
        font-weight: ${Font.WEIGHTSEMIBOLD};
        color: ${Colors.PRIMARY};
        text-decoration: none;
        transition: opacity .5s ease;

        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: ${Colors.PRIMARY};
            transform-origin: left;
            transform: scaleX(0);
            opacity: 0;
            transition: transform .5s ease, opacity .5s ease;
        }

        &:hover {
            opacity: 0.8;
            &::before {
                opacity: 1;
                transform: scaleX(1);
            }
        }

        &.logo {
            &::before {
                content: none;
            }
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
    }
`;
