import HeartIcon from 'assets/heart.svg';
import MouseIcon from 'assets/mouse.svg';
import { ButtonLink } from 'components/Button/Button';
import { SectionParagraph } from 'components/SectionParagraph/SectionParagraph';
import SocialMedia from 'components/SocialMedia/SocialMedia';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import * as Styled from './FooterStyles';

const Footer: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Styled.FooterWrapper>
      <Styled.FooterWrapperItem>
        <SectionParagraph>created with</SectionParagraph>
        <HeartIcon />
        <SectionParagraph>
          by{' '}
          <ButtonLink href="https://devticon.com/" title="devTicon" aria-label="devTicon" target="_blank">
            devTicon
          </ButtonLink>
        </SectionParagraph>
      </Styled.FooterWrapperItem>
      <Styled.FooterWrapperItem>
        <Styled.MouseButton>
          <MouseIcon />
        </Styled.MouseButton>
      </Styled.FooterWrapperItem>
      <Styled.FooterWrapperItem>
        <SectionParagraph>{formatMessage({ id: 'social.follow' })}</SectionParagraph>
        <SocialMedia />
      </Styled.FooterWrapperItem>
    </Styled.FooterWrapper>
  );
};

export default Footer;
